<template>
	<van-dialog class="HDialogB" v-model:show="propsData.show" :show-cancel-button="propsData.showCancelButton"
		:confirmButtonText="propsData.confirmButtonText" @cancel="cancel" @confirm="confirm" :beforeClose="beforeClose" theme="round-button">
		<template #title>
			<div class="HDialogB_title">
				<span>{{propsData.title.master}}</span>
				<img src="~@/assets/icon/icon-011.png">
				<span>{{propsData.title.guest}}</span>
			</div>
		</template>
		<div class="h12bgFFFFFF HDialogB_partition"></div>
		<slot></slot>
	</van-dialog>
</template>

<script>
	import {
		ref,
		reactive,
		watch
	} from 'vue'
	export default {
		name: "HDialogB",
		props: {
			title: {
				type: Object,
				default: () => {
					return {
						master: "主队",
						guest: "客队"
					}
				}
			},
			// dialog唯一值
			dialogKey: {
				type: String,
				required: true
			},
			// 是否显示
			show: {
				type: Boolean,
				default: false
			},
			// 是否展示取消按钮
			showCancelButton: {
				type: Boolean,
				default: false
			},
			// 确定按钮文本
			confirmButtonText: {
				type: String,
				default: "确定"
			}
		},
		setup(props, context) {
			console.log(props.showCancelButton);
			const propsData = reactive({
				title: props.title,
				show: props.show,
				showCancelButton: props.showCancelButton,
				confirmButtonText: props.confirmButtonText,
			});
			const propsTitle = ref(props.title);
			const cancel = () => {};
			const confirm = () => {};
			const beforeClose = (action) => {
				const {
					dialogKey
				} = props;
				context.emit("fBeforeClose", action, {
					dialogKey
				}, (tf) => {
					propsData.show = tf;
				})
			};
			// watch只能同时监听两个数值
			watch(
				[() => props.show],
				([newShow]) => {
					// console.log(newShow);
					propsData.show = newShow;
				}
			);
			watch(
				[() => props.title, () => props.showCancelButton],
				([newTitle]) => {
					console.log(newTitle)
					propsData.title = newTitle;
				},
				([newShowCancelButton]) => {
					propsData.showCancelButton = newShowCancelButton;
				}
			);
			return {
				propsTitle,
				propsData,
				cancel,
				confirm,
				beforeClose
			}
		}
	}
</script>

<style scoped lang="scss">
	.HDialogB_title {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.3466rem 0 0.2933rem;
		background-image: url('~@/assets/images/img_014.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		
		&>img {
			width: 0.3733rem;
			height: auto;
			margin: 0 0.32rem;
		}

		&>span {
			flex: 1;
			font-size: 0.4266rem;
			font-weight: 500;
			color: #383838;
		}

		&>span:first-child {
			text-align: right;
		}
		&>span:last-child {
			text-align: left;
		}
	}
</style>
