<template>
	<div class="basketball app_page app_flex">




<!--		v-if="oCPData.bang.isBang"-->

		<div class="basketball app_page app_flex" style="height: calc(100% - 3rem);padding-top: 0;">
			<div v-if="aMatch.length" class="basketball_body   app_scroll" style="padding: 0.2933rem 0.32rem 0;box-sizing: border-box">
				<van-collapse v-model="nActiveNames" :border="false">
					<van-collapse-item v-for="(item,index) in aMatch" class="basketball_day" :class="{'basketball_dayC': nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo" :key="index">
						<template #title>
							<div class="basketball_day_title">
								<div class="basketball_day_title_a">
								<span class="basketball_day_title_a_date">
									{{item.issueNo}}
								</span>
									<span class="basketball_day_title_a_week">
									{{fIsToday(item.issueNo) ? "今日" : item.weekStr}}
								</span>
								</div>
								<span v-show="nActiveNames.indexOf(item.issueNo) !== -1" class="basketball_day_title_b">
								共<em>{{item.gameNum}}</em>场比赛
							</span>
							</div>
							<div v-show="nActiveNames.indexOf(item.issueNo) === -1" class="basketball_day_close">
								当日共 <em>{{item.gameNum}}</em> 场比赛
							</div>
						</template>
						<template #right-icon>
							<div class="basketball_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) !== -1">
								<span class="basketball_day_rIcon_text">收起</span>
								<img class="basketball_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
							</div>
							<div class="basketball_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) === -1">
								<span class="basketball_day_rIcon_text">展开</span>
								<img class="basketball_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
							</div>
						</template>
						<div class="basketball_options" v-for="(itemA, indexA) in item.responseList" :key="indexA">
							<div class="basketball_options_title">
								<div class="basketball_options_title_a">
									<span>{{itemA.guestShortName}}(客)</span>
									<img src="~@/assets/icon/icon-011.png">
									<span>{{itemA.hostShortName}}(主)</span>
								</div>
								<div class="basketball_options_title_b">{{itemA.deadlineStr}}截止</div>
								<div class="basketball_options_title_c">
									<img class="basketball_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
									<div>
										<em>{{itemA.gameNo}}</em>
										<span>{{itemA.gameName}}</span>
									</div>
									<img class="basketball_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
								</div>
							</div>

							<div class="basketball_options_content">
								<div class="basketball_options_content_two">
									<div class="basketball_options_content_two_type">胜负</div>
									<div class="basketball_options_content_two_typeOption" v-if="itemA.supportSfGg">
										<template v-for="(itemB,indexB) in oCPData.basketballSfResponse" :key="indexB">
											<div :class="{'basketball_check': itemA.basketballSfResponse && itemA.basketballSfResponse[itemB.select],
											'basketball_single' : indexB === 0 && itemA.supportSfDg}" @click="fSelectOptions(index,indexA,itemB,'basketballSfResponse')">
												<p>{{itemB.name}}</p>
												<p>
													{{itemA.basketballSfResponse && itemA.basketballSfResponse[itemB.key]}}
												</p>
											</div>
										</template>
									</div>
									<div v-else class="basketball_options_none">
										当前玩法不支持投注
									</div>
								</div>
								<div class="basketball_options_content_two">
									<div class="basketball_options_content_two_type">让分</div>
									<div class="basketball_options_content_two_typeOption" v-if="itemA.supportRfsfGg">
										<template v-for="(itemB,indexB) in oCPData.basketballRfsfResponse" :key="indexB">
											<div :class="{'basketball_check': itemA.basketballRfsfResponse && itemA.basketballRfsfResponse[itemB.select],
											'basketball_single' : indexB === 0 && itemA.supportRfsfDg}" @click="fSelectOptions(index,indexA,itemB,'basketballRfsfResponse')">
												<p>{{itemB.name}} <em v-if="indexB === 1" class="basketball_options_badge">{{itemA.basketballRfsfResponse["pointSpread"]}}</em></p>
												<p>
													{{itemA.basketballRfsfResponse && itemA.basketballRfsfResponse[itemB.key]}}
												</p>
											</div>
										</template>
									</div>
									<div v-else class="basketball_options_none">
										当前玩法不支持投注
									</div>
								</div>
								<div class="basketball_options_content_two">
									<div class="basketball_options_content_two_type">大小分</div>
									<div class="basketball_options_content_two_typeOption" v-if="itemA.supportDxfGg">
										<template v-for="(itemB,indexB) in oCPData.basketballDxfResponse" :key="indexB">
											<div :class="{'basketball_check': itemA.basketballDxfResponse && itemA.basketballDxfResponse[itemB.select],
											'basketball_single' : indexB === 0 && itemA.supportDxfDg}" @click="fSelectOptions(index,indexA,itemB,'basketballDxfResponse')">
												<p>{{itemB.name}} <em class="basketball_options_badgeA">{{itemA.basketballDxfResponse["totalPoints"]}}</em></p>
												<p>
													{{itemA.basketballDxfResponse && itemA.basketballDxfResponse[itemB.key]}}
												</p>
											</div>
										</template>
									</div>
									<div v-else class="basketball_options_none">
										当前玩法不支持投注
									</div>
								</div>
								<div class="basketball_options_other" :class="{'basketball_options_other_select' : itemA.selectMore > 0}" @click="fOpenMore(index,indexA,itemA)">更多选项</div>
							</div>
						</div>
					</van-collapse-item>
				</van-collapse>
			</div>
			<div v-else class="basketball_none app_scroll">
				<van-empty class="basketball_none_empty" :image="require('@/assets/images/img_021.png')" description="暂无赛事" />
			</div>

			<div class="basketball_bottom">
				<div class="basketball_bottom_left">
					<!--                    <img src="~@/assets/icon/icon-015.png">-->
					<span v-show="aMatchSelect.length >0">已选 <em class="footBall_bottom_left_em2">{{aMatchSelect.length}}</em> 场</span>
					<span v-show="aMatchSelect.length === 0">非 <em class="basketball_bottom_left_em1">单关</em> 至少选择 <em
							class="basketball_bottom_left_em2">2</em>
					场比赛</span>
				</div>
				<span class="basketball_bottom_right" :class="{'basketball_bottom_right--disabled': bIsDisabled}" @click="fBet">
				立即投注
			</span>
			</div>

			<h-dialog-b :title="dialogB.title" dialogKey="dialogB" :show="dialogB.showDialog"
						:showCancelButton="dialogB.showCancelButton" :confirmButtonText="dialogB.confirmButtonText"
						@fBeforeClose="fBeforeClose">
				<div class="basketball_allOption app_scroll">
					<!--                    <van-button type="primary" @click="fSelectAll">全选</van-button>-->
					<div class="basketball_allOption_way">
						<div class="basketball_allOption_way_title">
							胜负 / 让分胜负
						</div>
						<div class="basketball_allOption_way_content">
							<div class="basketball_allOption_way_content_two">
								<div class="basketball_allOption_way_content_two_typeSF">0</div>
								<div v-if="dialogB.match.supportSfGg" class="basketball_allOption_way_content_two_typeOptions
								basketball_allOption_way_content_two_typeSFOptions">
									<template v-for="(itemB,indexB) in oCPData.basketballSfResponse" :key="indexB">
										<div :class="{'basketball_check': dialogB.match.basketballSfResponse && dialogB.match.basketballSfResponse[itemB.select],
										'basketball_single' : indexB === 0 && dialogB.match.supportSfDg}" @click="fSelectMoreOptions(itemB, 'basketballSfResponse')">
											<p>{{itemB.name}}</p>
											<p>
												{{dialogB.match.basketballSfResponse && dialogB.match.basketballSfResponse[itemB.key]}}
											</p>
										</div>
									</template>
								</div>
								<div v-else class="basketball_allOption_none basketball_allOption_noneAB">
									当前玩法不支持投注
								</div>
							</div>
							<div class="basketball_allOption_way_content_two">
								<div class="basketball_allOption_way_content_two_typeRFSF">让分</div>
								<div v-if="dialogB.match.supportRfsfGg" class="basketball_allOption_way_content_two_typeOptions basketball_allOption_way_content_two_typeRFSFOptions">
									<template v-for="(itemB,indexB) in oCPData.basketballRfsfResponse" :key="indexB">
										<div :class="{'basketball_check': dialogB.match.basketballRfsfResponse && dialogB.match.basketballRfsfResponse[itemB.select],
										'basketball_single' : indexB === 0 && dialogB.match.supportRfsfDg}" @click="fSelectMoreOptions(itemB, 'basketballRfsfResponse')">
											<p>{{itemB.name}} <em v-if="indexB === 1" class="basketball_options_badge">{{dialogB.match.basketballRfsfResponse["pointSpread"]}}</em></p>
											<p>
												{{dialogB.match.basketballRfsfResponse && dialogB.match.basketballRfsfResponse[itemB.key]}}
											</p>
										</div>
									</template>
								</div>
								<div v-else class="basketball_allOption_none basketball_allOption_noneCD">
									当前玩法不支持投注
								</div>
							</div>
						</div>
					</div>

					<div class="basketball_allOption_way">
						<div class="basketball_allOption_way_title">
							<img v-if="dialogB.match.supportSfDg" src="~@/assets/icon/icon-012.png"> 大小分
						</div>
						<div class="basketball_allOption_way_content">
							<div class="basketball_allOption_way_content_bf">
								<div class="basketball_allOption_way_content_bf_type">平</div>
								<div v-if="dialogB.match.supportDxfGg" class="basketball_allOption_way_content_bf_typeOptions">
									<div :class="{'basketball_check': dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[0].select]}" @click="fSelectMoreOptions(oCPData.basketballDxfResponse[0], 'basketballDxfResponse')">
										<p>{{oCPData.basketballDxfResponse[0].name}}</p>
										<p>
											{{dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[0].key]}}
										</p>
									</div>
									<span class="basketball_position">
									{{dialogB.match.basketballDxfResponse["totalPoints"]}}
								</span>
									<div :class="{'basketball_check': dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[1].select]}" @click="fSelectMoreOptions(oCPData.basketballDxfResponse[1], 'basketballDxfResponse')">
										<p>{{oCPData.basketballDxfResponse[1].name}}</p>
										<p>
											{{dialogB.match.basketballDxfResponse && dialogB.match.basketballDxfResponse[oCPData.basketballDxfResponse[1].key]}}
										</p>
									</div>
								</div>
								<div v-else class="basketball_allOption_none app_h44">
									当前玩法不支持投注
								</div>
							</div>
						</div>
					</div>

					<div class="basketball_allOption_way">
						<div class="basketball_allOption_way_title">
							<img v-if="dialogB.match.supportSfcDg" src="~@/assets/icon/icon-012.png"> 胜分差
						</div>

						<div class="basketball_allOption_way_content">
							<div class="basketball_allOption_way_content_three">
								<div class="basketball_allOption_way_content_three_typeSFC">
								<span>
									客胜
								</span>
									<span>
									主胜
								</span>
								</div>

								<div v-if="dialogB.match.supportSfcGg" class="basketball_allOption_way_content_three_typeOptions">
									<template v-for="(itemB,indexB) in oCPData.jcwJclqSfcResponseK" :key="indexB">
										<div :class="{'basketball_check': dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.select]}" @click="fSelectMoreOptions(itemB, 'basketballSfcResponse')">
											<p>{{itemB.name}}</p>
											<p>
												{{dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.key]}}
											</p>
										</div>
									</template>
									<template v-for="(itemB,indexB) in oCPData.jcwJclqSfcResponseZ" :key="indexB">
										<div :class="{'basketball_check': dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.select]}" @click="fSelectMoreOptions(itemB, 'basketballSfcResponse')">
											<p>{{itemB.name}}</p>
											<p>
												{{dialogB.match.basketballSfcResponse && dialogB.match.basketballSfcResponse[itemB.key]}}
											</p>
										</div>
									</template>
								</div>
								<div v-else class="basketball_allOption_none">
									当前玩法不支持投注
								</div>
							</div>
						</div>
					</div>

				</div>
			</h-dialog-b>
<!--			<h-dialog-c title="提示" dialogKey="dialogC" :show="dialogC.showDialog"-->
<!--						:showCancelButton="dialogC.showCancelButton" :confirmButtonText="dialogC.confirmButtonText"-->
<!--						@fBeforeClose="fBeforeClose">-->
<!--				<div class="footBall_tips">-->
<!--					当前有未提交的购彩信息，如果执行返回您将自动放弃购彩信息！-->
<!--				</div>-->
<!--			</h-dialog-c>-->
		</div>





	</div>
</template>

<script>
	import {
		ref,
		reactive,
		computed,
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRouter,
		// onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import HDialogB from "@/components/HDialog/HDialogB/index.vue";
	// import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import tipsContent from '@/utils/tipsContent';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnDeepClone,
		fnIsToday,
		fnObjForArraySort
	} from "@/utils/public.js";
	import {
		apiJCLQ
	} from '@/api/allplay.js';
	export default {
		name: "Basketball",
		components: {
			HDialogB,
			// HDialogC
		},
		setup() {
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const fIsToday = fnIsToday;
			const UserInfo = store.state.userInfo.userInfo;
			// 当前页面数据
			const oCPData = reactive({
				// 胜负
				basketballSfResponse: [{
						name: '客胜',
						key: 'aSfSp',
						select: 'aSfSpSelect',
						sort: 0
					},
					{
						name: '主胜',
						key: 'hSfSp',
						select: 'hSfSpSelect',
						sort: 1
					}
				],
				// 让分胜负
				basketballRfsfResponse: [{
					name: '客胜',
					key: 'aRfsfSp',
					select: 'aRfsfSpSelect',
					sort: 2
				}, {
					name: '主胜',
					key: 'hRfzsSp',
					select: 'hRfzsSpSelect',
					sort: 3
				}],
				// 大小分
				basketballDxfResponse: [{
					name: '大于',
					key: 'hDxfSp',
					select: 'hDxfSpSelect',
					sort: 4
				}, {
					name: '小于',
					key: 'lDxfSp',
					select: 'lDxfSpSelect',
					sort: 5
				}],
				//胜分差客胜
				jcwJclqSfcResponseK: [{
						name: '客胜1~5',
						key: 'l1SfcSp',
						select: 'l1SfcSpSelect',
						sort: 6
					},
					{
						name: '客胜6~10',
						key: 'l2SfcSp',
						select: 'l2SfcSpSelect',
						sort: 7
					},
					{
						name: '客胜11~15',
						key: 'l3SfcSp',
						select: 'l3SfcSpSelect',
						sort: 8
					},
					{
						name: '客胜16~20',
						key: 'l4SfcSp',
						select: 'l4SfcSpSelect',
						sort: 9
					},
					{
						name: '客胜21~25',
						key: 'l5SfcSp',
						select: 'l5SfcSpSelect',
						sort: 10
					},
					{
						name: '客胜26+',
						key: 'l6SfcSp',
						select: 'l6SfcSpSelect',
						sort: 11
					}
				],
				//胜分差主胜
				jcwJclqSfcResponseZ: [{
						name: '主胜1~5',
						key: 'w1SfcSp',
						select: 'w1SfcSpSelect',
						sort: 12
					},
					{
						name: '主胜6~10',
						key: 'w2SfcSp',
						select: 'w2SfcSpSelect',
						sort: 13
					},
					{
						name: '主胜11~15',
						key: 'w3SfcSp',
						select: 'w3SfcSpSelect',
						sort: 14
					},
					{
						name: '主胜16~20',
						key: 'w4SfcSp',
						select: 'w4SfcSpSelect',
						sort: 15
					},
					{
						name: '主胜21~25',
						key: 'w5SfcSp',
						select: 'w5SfcSpSelect',
						sort: 16
					},
					{
						name: '主胜26+',
						key: 'w6SfcSp',
						select: 'w6SfcSpSelect',
						sort: 17
					}
				],
			});
			// 更多选项弹框
			const dialogB = reactive({
				title: {
					master: "主队",
					guest: "客队"
				},
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "确定",
				index: -1,
				indexA: -1,
				match: {}
			});
			// dialogC
			const dialogC = reactive({
				isSure: false, // 确定退出
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "继续返回"
			});

			// van-collapse的唯一值
			const nActiveNames = ref([]);
			// 是否禁用立即投注按钮
			const bIsDisabled = computed(() => {
				// debugger
				console.log(aMatchSelect)
				const tAMatchSelect = aMatchSelect.value;
				let tBIsDisabled = true

				if (tAMatchSelect.length > 1) {
					tBIsDisabled = false;
				} else if (tAMatchSelect.length === 1) {
					// 该数据选中非单关不存在，并且单关存在
					if (tAMatchSelect[0].selectNoDg <= 0 && tAMatchSelect[0].selectDg > 0) {
						tBIsDisabled = false;
					} else {
						tBIsDisabled = true;
					}
				} else {
					tBIsDisabled = true;
				}
				return tBIsDisabled
			});
			// 赛事列表
			const aMatch = ref([]);
			// 赛事列表-备份
			const aMatch_BackUp = ref([]);
			// 已选择赛事信息
			const aMatchSelect = computed(() => {
				return store.state.allPlay.BBSelected
			});
			// 全部赛事
			const fGetMatch = () => {
				Toast({
					type: "loading"
				});
				apiJCLQ({
					roleId: UserInfo.roleId
				}).then(res => {
					console.log("请求全部赛事");
					let tData = res.result;
					console.log(tData);
					aMatch.value = fnDeepClone(tData);
					if (tData.length > 0) nActiveNames.value = [tData[0].issueNo];
					aMatch_BackUp.value = fnDeepClone(tData);

					fEcho();
				})
			};
			fGetMatch();

			// 数据回显
			const fEcho = () => {
				console.log("开始回显");
				let tAMatchSelect = aMatchSelect.value;
				console.log(tAMatchSelect);
				const tAMatch = aMatch.value;
				let tCAMatchSelect = fnDeepClone(tAMatchSelect);
				if (tAMatchSelect.length === 0) return;
				console.log(tAMatchSelect)

				tCAMatchSelect.forEach((itemA, indexA) => {
					let tNoSame = false;
					tAMatch.forEach(itemB => {
						itemB.responseList.forEach((itemC, indexC) => {
							// 注意 注意 注意
							// 如果赛事胜平负新的数据是单关，但是回显数据并不是单关，那么则删除
							// 同理 如果赛事过关开关与回显数据不同，那么则删除
							if (itemA.id === itemC.id &&
								itemA.supportSfGg === itemC.supportSfGg &&
								itemA.supportSfDg === itemC.supportSfDg &&
								itemA.supportRfsfGg === itemC.supportRfsfGg &&
								itemA.supportRfsfDg === itemC.supportRfsfDg &&
								itemA.supportDxfGg === itemC.supportDxfGg &&
								itemA.supportDxfDg === itemC.supportDxfDg &&
								itemA.supportSfcGg === itemC.supportSfcGg &&
								itemA.supportSfcDg === itemC.supportSfcDg) {
								tNoSame = true;
								// 如果玩法的过关开启，则合并选项，如果未开启，则使用新数据
								itemA.basketballSfResponse = itemC.supportSfGg ? {
									...itemA.basketballSfResponse,
									...itemC.basketballSfResponse
								} : itemC.basketballSfResponse;
								itemA.basketballRfsfResponse = itemC.supportRfsfGg ? {
									...itemA.basketballRfsfResponse,
									...itemC.basketballRfsfResponse
								} : itemC.basketballRfsfResponse;
								itemA.basketballDxfResponse = itemC.supportDxfGg ? {
									...itemA.basketballDxfResponse,
									...itemC.basketballDxfResponse
								} : itemC.basketballDxfResponse;
								itemA.jcwJclqSfcResponse = itemC.supportSfcGg ? {
									...itemA.jcwJclqSfcResponse,
									...itemC.jcwJclqSfcResponse
								} : itemC.jcwJclqSfcResponse;
								itemB.responseList[indexC] = fnDeepClone(itemA);
							}
						})
					})
					if (!tNoSame) delete tAMatchSelect[indexA];
				})

				// 处理后的数据
				let tHandleData = tAMatchSelect.filter(item => item !== undefined);

				tAMatchSelect = fnDeepClone(tHandleData);
				store.dispatch("allPlay/UBBSelected", fnDeepClone(tHandleData));
				console.log(aMatch.value)
			}
			/**
			 * [fSelectOptions 选择比赛选项]
			 * @param  {[Number]} Index [所属期次号下标]
			 * @param  {[Number]} IndexA [当前比赛下标]
			 * @param  {[Object]} Option [当前比赛选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @return {[Null]}
			 */
			const fSelectOptions = (Index, IndexA, Option, ObjName) => {
				const tMatchToday = aMatch.value[Index];
				const tMatch = tMatchToday["responseList"][IndexA];
				tMatch["issueNo"] = tMatchToday["issueNo"];
				fnHandleSelectData("all", tMatch, Option, ObjName);
			};


			/**
			 * [fnHandleSelectData 处理选中数据]
			 * @param  {[String]} IsDo [是否开始 从aMatchSelect中合并或者移除]
			 * @param  {[Object]} Match [当前赛事信息]
			 * @param  {[Object]} Option [当前比赛选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @return {[Null]}
			 */
			const fnHandleSelectData = (IsDo, Match, Option, ObjName) => {
				console.log(IsDo, Match, Option, ObjName)
				if (IsDo === "all" || IsDo === "first") {
					if (aMatchSelect.value.length === 8) {
						// 当前赛事是否已添加到选项中
						let tAMatchSelect = aMatchSelect.value;
						// 是否存在
						let tIsHas = tAMatchSelect.filter(item => item.id === Match.id);
						if (tIsHas.length === 0 && !Match[ObjName][Option.select]) {
							return Toast({
								message: tipsContent.maxSession,
								position: 'bottom'
							});
						}
					}

					Match[ObjName][Option.select] = !Match[ObjName][Option.select];
					// 单关对应名字
					let dgName = false;
					// 设置单关对应字段
					switch (ObjName) {
						case "basketballSfResponse":
							dgName = "supportSfDg";
							break;
						case "basketballRfsfResponse":
							dgName = "supportRfsfDg";
							break;
						case "basketballDxfResponse":
							dgName = "supportDxfDg";
							break;
						case "basketballSfcResponse":
							dgName = "supportSfcDg";
							break;
						default:
							dgName = false;
							break;
					}

					// 计算已选中
					Match.selectNum = Match.selectNum ? Match.selectNum : 0;
					// 计算已选中单关
					Match.selectDg = Match.selectDg ? Match.selectDg : 0;
					// 计算已选中非单关：用于判断只有一场赛事时是否同时选中单关和非单关选项
					Match.selectNoDg = Match.selectNoDg ? Match.selectNoDg : 0;
					// 计算已选中更多
					Match.selectMore = Match.selectMore ? Match.selectMore : 0;

					// 当前赛事选中的选项为true
					if (Match[ObjName][Option.select]) {
						console.log(Match[ObjName])
						// 组装数据，方便投注页面使用
						let tObj = {
							name: Option.name,
							award: Match[ObjName][Option.key],
							key: Option.key,
							sort: Option.sort,
						};
						if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
							let tIsHasOption = Match[ObjName + "Select"].filter(item => item.key === tObj.key);
							if (tIsHasOption.length === 0) Match[ObjName + "Select"].push(tObj);
							fnObjForArraySort(Match[ObjName + "Select"], "sort");
						} else {
							Match[ObjName + "Select"] = [tObj];
						}


						Match.selectNum += 1;
						// 判断当前赛事选中的选项是否支持单关
						if (Match[dgName]) {
							Match.selectDg += 1;
						} else {
							Match.selectNoDg += 1;
						}
					} else {
						// 组装数据，方便投注页面使用
						let tObj = {
							name: Match[ObjName][Option.name],
							award: Match[ObjName][Option.key],
							key: Option.key,
							sort: Option.sort,
						};
						if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
							Match[ObjName + "Select"].forEach((item, index) => {
								if (item.key === tObj.key) {
									delete Match[ObjName + "Select"][index]
								}
							});
							Match[ObjName + "Select"] = fnDeepClone(Match[ObjName + "Select"].filter(item => item !==
								undefined));
						} else {
							Match[ObjName + "Select"] = [];
						}

						if (Match.selectNum > 0) Match.selectNum -= 1;
						// 判断当前赛事选中的选项是否支持单关
						if (Match[dgName]) {
							if (Match.selectDg > 0) Match.selectDg -= 1;
						} else {
							if (Match.selectNoDg > 0) Match.selectNoDg -= 1;
						}
					}

					// 判断当前选中选项是否为更多选项中独有的选项
					if (ObjName === 'jcwJclqSfcResponse') {
						if (Match[ObjName][Option.select]) {
							Match.selectMore += 1;
						} else {
							if (Match.selectMore > 0) Match.selectMore -= 1;
						}
					}
				}

				if (IsDo === "all" || IsDo === "second") {
					let tMatch = fnDeepClone(Match)
					// 当前赛事是否已添加到选项中
					let tAMatchSelect = aMatchSelect.value;
					// 是否已添加到选项中
					let tIsHas = false;
					for (let i = 0; i < tAMatchSelect.length; i++) {
						if (tAMatchSelect[i].id === Match.id) {
							// 当前赛事已选中数量大于0
							if (Match.selectNum > 0) {
								// 已添加则更新
								tIsHas = true;
								tAMatchSelect[i] = tMatch;
							} else {
								tAMatchSelect.splice(i, 1)
								break;
							}
						}
					}
					// 当前赛事已选中数量大于0 并且 未添加则添加
					if (Match.selectNum > 0 && !tIsHas) tAMatchSelect.push(tMatch);

					console.log(aMatchSelect);
				}

			}

			/**
			 * [fOpenMore 选择比赛选项]
			 * @param  {[Number]} Index [所属期次号下标]
			 * @param  {[Number]} IndexA [当前比赛下标]
			 * @param  {[Object]} Match [当前比赛信息]
			 * @return {[Null]}
			 */
			// 更多选项弹框--打开 (赛事)
			const fOpenMore = (Index, IndexA, Match) => {
				const tMatchToday = aMatch.value[Index];
				Match["issueNo"] = tMatchToday["issueNo"];

				console.log(Match);
				dialogB.title = {
					master: Match.guestShortName+"(客)",
					guest: Match.hostShortName+"(主)"
				}
				dialogB.showDialog = true;
				dialogB.index = Index;
				dialogB.indexA = IndexA;
				dialogB.match = fnDeepClone(Match)
				console.log(dialogB);
			};
			/**
			 * [fSelectMoreOptions 选择比赛更多选项]
			 * @param  {[Object]} Option [当前选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @return {[Null]}
			 */
			const fSelectMoreOptions = (Option, ObjName) => {
				fnHandleSelectData("first", dialogB.match, Option, ObjName)
			};
			// 更多选项弹框--关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "dialogB") {
					if (action === "confirm") {
						console.log("确定选择");
						fnHandleSelectData("second", dialogB.match);
						aMatch.value[dialogB.index]["responseList"][dialogB.indexA] = fnDeepClone(
							dialogB
							.match);
						dialogB.showDialog = false;
						return fn(false)
					} else if (action === "cancel") {
						console.log("关闭弹框");
						dialogB.showDialog = false;
						return fn(false)
					}
				} else if (dialogKey === "dialogC") {
					if (action === "confirm") {
						dialogC.showDialog = false;
						dialogC.isSure = true;
						fnIsHasHistory("/");
						return fn(false)
					} else if (action === "cancel") {
						dialogC.showDialog = false;
						dialogC.isSure = false;
						return fn(false)
					}
				}
			}

			// 清除所有
			const fCleanAll = () => {
				aMatch.value = fnDeepClone(aMatch_BackUp.value);
				aMatchSelect.value = [];
				store.dispatch("allPlay/UBBSelected", []);
			};
			// 立即投注
			const fBet = () => {
				if (bIsDisabled.value) return;
				store.dispatch("allPlay/UBBSelected", aMatchSelect.value);
				store.dispatch("allPlay/UOrderData", {});
				router.push({
					path: '/betLQ'
				})
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory();
			};
			// fRight van-nav-bar 右侧点击事件
			const fRight = () => {
				router.push({
					path: '/generalTxt',
					query: {
						agreement: 'playWayLQ'
					}
				})
			};
			// //离开当前的组件，触发
			// onBeforeRouteLeave((to) => {
			// 	if (!dialogC.isSure) {
			// 		// 白名单
			// 		const WHITE_LIST = ['/betLQ', '/generalTxt'];
			// 		// 确定返回
			// 		if (WHITE_LIST.indexOf(to.path) === -1) {
			// 			// 前往地址是否为投注地址--否
			// 			if (aMatchSelect.value.length > 0) {
			// 				// 赛事选项是否存在--是
			// 				const timer = setTimeout(() => {
			// 					dialogC.showDialog = true;
			// 					clearTimeout(timer);
			// 				}, 100)
			// 				return false;
			// 			}
			// 		}
			// 	} else {
			// 		// 确定返回--是
			// 		store.dispatch("allPlay/UBBSelected", []);
			// 	}
			// })
			return {
				nActiveNames,

				oCPData,
				bIsDisabled,
				dialogC,
				dialogB,
				aMatch,
				aMatchSelect,


				fSelectOptions,
				fOpenMore,
				fSelectMoreOptions,
				fBeforeClose,

				fCleanAll,
				fBet,
				fLeft,
				fRight,
				fIsToday,
			}
		}
	}
</script>

<style scoped lang="scss">
	.match{
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		padding-top: 1.247rem;
		box-sizing: border-box;
		.gendan{
			width: calc(100% - 0.64rem);
			margin: 0 auto;
			background: #F1F2F7;
			/*background: #FAFBFF;*/
			/*box-shadow: 0px 0.053rem 0.427rem 0px rgba(224,228,246,0.5);*/
			/*border-radius: 0.32rem;*/
			position: fixed;
			left: 0.32rem;
			top: 0;
			z-index: 999;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.bang_top{
				width: 100%;
				height: 1.147rem;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				margin-bottom: 0.4rem;
				.bang_title_box{
					/*width: 1.707rem;*/
					/*height: 100%;*/
					.bang_title{
						/*width: 1.707rem;*/
						height: 0.587rem;
						font-size: 0.427rem;
						font-weight: 600;
						color: #383838;
						line-height: 0.587rem;
						position: relative;
						img{
							width: 0.507rem;
							height: 0.16rem;
							position: absolute;
							left: calc((100% - 0.507rem)/2);
							bottom: -0.187rem;
						}
					}

				}
			}
			.gz{
				width: 0.373rem;
				height: 0.373rem;
			}


		}

	}
	.footBall {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		/*background-image: url('~@/assets/images/img_011.png');*/
		background-size: 100% auto;
		background-repeat: no-repeat;

		.footBall_none {
			margin: 0.2933rem 0.32rem 0;
			background: #FAFBFF;
			box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.2133rem 0.2133rem 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.footBall_body {
			padding: 0.2933rem 0.32rem 0;

			.footBall_day {
				background: #FAFBFF;
				box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;
			}
		}

		.footBall_bottom {
			width: calc(100% - 0.64rem);
			padding: 0.2933rem 0.4rem;
			box-sizing: border-box;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: absolute;
			bottom:1.8rem;
			z-index: 999;
			left: 0.32rem;

			.footBall_bottom_left {
				display: flex;
				align-items: center;

				&>img {
					width: 0.5333rem;
				}

				&>span {
					margin-left: 0.32rem;
					font-size: 0.32rem;
					color: #383838;

					.footBall_bottom_left_em1 {
						color: #E2335D
					}

					.footBall_bottom_left_em2 {
						color: #FF7671
					}
				}
			}

			.footBall_bottom_right {
				padding: 0.16rem 0.6133rem;
				background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
				border-radius: 0.4266rem;

				font-size: 0.3733rem;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 0.5333rem;
			}

			.footBall_bottom_right--disabled {
				background: #FFABA8;
			}
		}


	}
	.footBall_tips {
		padding: 0.2666rem 0.64rem;
		margin-bottom: 0.5333rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}
	.dianz{
		width: 100%;
		height: 1.227rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.dianz_item{
			display: flex;
			align-items: center;
			img{
				width: 0.48rem;
				height: 0.48rem;
				margin-right: 0.107rem;
			}
			div{
				font-size: 0.373rem;
				font-weight: 400;
				color: #B4B4B4;
			}
		}
	}
	.basketball_bottom{
		width: calc(100% - 0.64rem);
		padding: 0.2933rem 0.4rem;
		box-sizing: border-box;
		background: #FFFFFF;
		border-radius: 0.2133rem 0.2133rem 0px 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		bottom:1.8rem;
		z-index: 999;
		left: 0.32rem;

		.basketball_bottom_left {
			display: flex;
			align-items: center;

			&>img {
				width: 0.5333rem;
			}

			&>span {
				margin-left: 0.32rem;
				font-size: 0.32rem;
				color: #383838;

				.footBall_bottom_left_em1 {
					color: #E2335D
				}

				.footBall_bottom_left_em2 {
					color: #FF7671
				}
			}
		}

		.basketball_bottom_right {
			padding: 0.16rem 0.6133rem;
			background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
			border-radius: 0.4266rem;

			font-size: 0.3733rem;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 0.5333rem;
		}

		.footBall_bottom_right--disabled {
			background: #FFABA8;
		}

	}
</style>
