import request from '@/utils/request';
import consts from '@/utils/consts';

// 展示比赛信息(竞猜足球)
export function apiJCZQ(data) {
	return request({
		url: consts.businessAnonymous + '/football/showAllMatches',
		method: 'post',
		data: data,
	})
}
// 展示比赛信息(竞猜篮球)
export function apiJCLQ(data) {
	return request({
		url: consts.businessAnonymous + '/basketball/showAllMatches',
		method: 'post',
		data: data,
	})
}
// 保存投注内容
export function saveBetContent(data) {
	return request({
		url: consts.businessPublic + '/plan/saveBetContent',
		method: 'post',
		data: data,
	})
}

// 展示比赛信息(北京单场)
export function apiBJDC(data) {
	return request({
		url: consts.businessPublic + '/bjlot/showAllMatches',
		method: 'post',
		data: data,
	})
}

// 方案计算
export function apiPlanCalculate(data) {
	return request({
		url: consts.businessAnonymous + '/plan/calculate',
		method: 'post',
		data: data
	})
}

// 奖金优化
export function apiPlanOptimize(data) {
	return request({
		url: consts.businessPublic + '/plan/bonusOptimize',
		method: 'post',
		data: data
	})
}

// 理论奖金计算
export function apiPlanTheoryBonus(data) {
	return request({
		url: consts.businessPublic + '/plan/theoryBonus',
		method: 'post',
		data: data
	})
}

// 方案下注关联彩票店
export function apiPlanLotteryShop(data) {
	return request({
		url: consts.businessPublic + '/plan/planLotteryShop',
		method: 'post',
		data: data
	})
}

// 创建方案
export function apiPlanCreate(data) {
	return request({
		url: consts.businessPublic + '/plan/create',
		method: 'post',
		data: data
	})
}
// 支付前盘口校验
export function apiOrderPayPlateCheck(data) {
	return request({
		url: consts.businessPublic + '/order/payPlateCheck',
		method: 'post',
		data: data
	})
}

// 订单支付新增返回字段，只有code200，并且payResult为true时才代表支付成功，code200并且payResult代表支付失败，具体错误信息看失败原因枚举字段
/**
 * [aOrderPay  订单支付]
 * @param  {[Object]} data [ 请求参数 ]
 * @return {
		"code": "200",提示信息
		"message": "操作成功!",状态码
		"result": {返回业务数据
			"message": "",支付失败原因message
			"orderUnPayReasonEnum": "",支付失败类型
			"payResult": true 支付是否成功,true:成功;false:失败
		},
		"success": true 请求成功与否,true:成功,false:失败
	} [ 相应参数 ]
 * orderUnPayReasonEnum 字段补充说明
 *   PAID(1, "您已完成支付，请勿重复支付"),
 *   PAY_NONSUPPORT(11, "暂不支持该支付方式"),
 *   AMOUNT_ERROR(21, "金额错误"),
 *   AMOUNT_NOT_ENOUGH(31, "余额不足"),
 *   HAVE_UN_PAY_FEE(41,"有未支付的佣金"),
 *   ORDER_PAY_ERROR(51,"彩店出票失败")
 */
// 订单支付
export function apiOrderPay(data) {
	return request({
		url: consts.businessPublic + '/order/pay',
		method: 'post',
		data: data
	})
}

// 获取收益保障金范围值
export function apiGetProfitProtectRateScope(data) {
	return request({
		url: consts.businessPublic + '/plan/getProfitProtectRateScope',
		method: 'post',
		data: data
	})
}

// 方案发布跟单
export function apiPlanIssueDocumentary(data) {
	return request({
		url: consts.businessPublic + '/plan/issueDocumentary',
		method: 'post',
		data: data
	})
}

// 已有方案ID获取彩店出票明细
export function apiPlanPayDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/payDetail',
		method: 'post',
		data: data
	})
}

// 创建跟单方案
export function apiCreatePlanDocumentary(data) {
	return request({
		url: consts.businessPublic + '/plan/createPlanDocumentary',
		method: 'post',
		data: data
	})
}
